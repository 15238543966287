import { useAuthStore } from "../store/app/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {

  const authStore = useAuthStore();

  if (authStore.isAdmin) {
    return;
  }

  return navigateTo("/login");

});
